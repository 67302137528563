<template>
  <div class="lesson">

    <div class="title">个人信息</div>
    <div class="centerMain">
        <div class="content">
          <p><span>姓名：</span>{{info.name}}</p>
          <p><span>性别：</span>{{info.gender == 1 ? '男' : info.gender == 2 ? '女' : ''}}</p>
          <p><span>手机号：</span>{{info.mobile}}</p>
        </div>
        <div class="content">
          <p><span>所在单位:</span>{{info.remark}}</p>
          <p><span>省市区：</span>{{info.province_name}} - {{ info.city_name }} - {{ info.district_name}}</p>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "lesson",
  data() {
    return {
      info:{},
      pageLoading: true,
    };
  },
  async mounted() {
    this.info = JSON.parse(window.localStorage.getItem('user'))
    // this.getData();
  },
  destroyed() {
  },
  computed: {
    plat() {
      return this.$store.state.plat_id;
    },
  },
  methods: {
    getTag(index) {
      this.tag = index;
    },
    async getData() {
      this.pageLoading = true;
        let res = await this.$request.userInfo();
        this.pageLoading = false;
        if (res.code == 0) {
          this.info = res.data;
        } else {
          this.$toast(res.msg);
        }
        
    },

    
    
  },
  beforeRouteUpdate(to, from, next) {
    console.log("beforeRouteUpdate");
    console.log(to);
    next();
  },
};
</script>

<style lang="scss" scoped>
.lesson {
  min-height: calc(100vh - 230px);
  overflow: hidden;
  font-family: "SourceHanSans";
  .title{
    
    font-size: 24px;
    color: #242424;
    font-weight: bold;
    border-bottom: 1px dashed #f0f0f0;
    padding-top: 30px;
    padding-bottom: 20px;
    width: 100%;
  }
  .centerMain{
    width: 1000px;
    margin: 30px auto 0;
    background: #f9f9f9;
    border-radius: 8px;
    padding: 30px;
    .content{
      flex: 1;
      p{
        color: #666666;
        font-size: 16px;
        line-height: 60px;
        span{
          color: #333333;
          display: inline-block;
          width: 106px;
        }
      }
      &:first-of-type p{
        width: 33.3%;
        display: inline-block;
      }
    }
    img{
      width: 124px;
      height: 173px;
      border-radius: 8px;
      overflow: hidden;
      object-fit: cover;
    }
  }
}
</style>

<style lang="scss">
body {
  background: #fff;
}
</style>
